<template>
	<div class="container">
		<Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
			<Tab title="직위 관리">
				<div class="locker_setting_list sub_new_style01 sub_ui_box1">
					<div class="cusmain-table-wrap">
						<esp-dx-data-grid :data-grid="dataGrid.grade" ref="gradeGrid" @init-new-row="onInitNewRow" @saving="onSaving">
						</esp-dx-data-grid>
					</div>
				</div>
			</Tab>
			<Tab title="직책 관리">
				<div class="locker_setting_list sub_new_style01 sub_ui_box1">
					<div class="cusmain-table-wrap">
						<esp-dx-data-grid :data-grid="dataGrid.position" ref="positionGrid" @init-new-row="onInitNewRow" @saving="onSaving">
						</esp-dx-data-grid>
					</div>
				</div>
			</Tab>
		</Tabs>
	</div>
</template>

<script>
import Tabs from '@/components/common/tabs.vue';
import Tab from '@/components/common/tab.vue';

import { isSuccess } from '@/plugins/common-lib';
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

let vm = null;

export default {
	components: {
    EspDxDataGrid,
		Tabs,
		Tab,
	},
	props: {},
	watch: {},
	data() {
		return {
			tabs: {
				selectedIndex: this.$store.getters.getTabHists[this.$router.currentRoute.path],
			},
			config: {
				pageSetting: {
					contentsData: {
						search: {},
						dataGrid: {
							grade: {
								refName: 'gradeGridRefName',
								allowColumnResizing: true, //컬럼 사이즈 허용
								showBorders: false, //border 유무
								showColumnHeaders: true, //컬럼 헤더 유무
								showColumnLines: false, //컬럼 세로선 유무
								showRowLines: true, //컬럼 가로선 유무
								rowAlternationEnabled: false,
								hoverStateEnabled: false,
								dataSource: [],
								dataSourceDefaultSortColumn: '+gradeOrd', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순
								// width:'200',
								height: 'calc(100vh - 300px)', // 주석처리시 100%
								apiActionNm: {
									selectGrade: 'GRADE_LIST_SELECT', //dx-data-grid 컴포넌트의 select 메서드 호출
									update: 'GRADE_LIST_UPDATE',
									delete: 'GRADE_LIST_DELETE',
								},
								showActionButtons: {
									// 그리드 버튼 노출 설정값
									select: false,
									update: true,
									delete: true,
									customButtons: [],
								},
								customEvent: {
									initNewRow: true,
									saving: true,
								},
								dragging: {
									sortColumn: 'gradeOrd',
									allowReordering: true,
									dropFeedbackMode: 'push', // 설정하면 dragging 할때 기존리스트가 아래로 움직이는 효과
								},
								grouping: {
									contextMenuEnabled: false,
									autoExpandAll: false,
									allowCollapsing: false,
									expandMode: 'rowClick', // rowClick or buttonClick
								},
								groupPanel: {
									visible: false,
								},
								columnChooser: {
									enabled: false, // 컬럼 Chooser 버튼 사용유무
								},
								loadPanel: {
									enabled: false, // 로딩바 표시 유무
								},
								sorting: {
									mode: 'multiple', // single multiple
								},
								scrolling: {
									// 미사용시 주석처리
									mode: 'virtual', //스크롤 모드 : ['infinite', 'standard', 'virtual']
								},
								remoteOperations: {
									filtering: false,
									sorting: false,
									grouping: false,
									paging: false,
								},
								paging: {
									// scrolling 미사용시만 적용됨
									enabled: true,
									pageSize: 10,
									pageIndex: 0, // 시작페이지
								},
								pager: {
									visible: false, //페이저 표시 여부
									showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
									allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
									displayMode: 'compact', //표시 모드 : ['full', 'compact']
									showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
									showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
								},
								filterRow: {
									visible: false,
								},
								headerFilter: {
									visible: true,
								},
								editing: {
									allowUpdating: true,
									allowDeleting: false,
									allowAdding: true,
									mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
									startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
									selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
								},
								selecting: {
									mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
									selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
									showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
								},
								columns: [
									{
										caption: '순서',
										dataField: 'gradeOrd',
										width: 100,
										//height: 40,
										alignment: 'center', // left center right
										visible: false,
										allowEditing: true,
										sortOrder: 'none', // acs desc none
										allowHeaderFiltering: false,
										fixed: false, // 컬럼 fix 시 사용
										fixedPosition: 'left', // left or right
									},
									{
										caption: '사이트*',
										dataField: 'siteId',
										//width: 70,
										//height: 40,
										alignment: 'center', // left center right
										visible: true,
										//allowEditing: false,
										sortOrder: 'none', // acs desc none
										allowHeaderFiltering: false,
										fixed: false, // 컬럼 fix 시 사용
										fixedPosition: 'left', // left or right
										lookup: {
											dataSource: this.$store.getters.getSiteList,
											displayExpr: 'siteNm',
											valueExpr: 'id',
										},
										requiredRule: {
											message: '사이트는 필수입니다.',
										},
									},

									{
										caption: '직위명*',
										dataField: 'gradeNm',
										//width: 70,
										//height: 40,
										alignment: 'center', // left center right
										visible: true,
										//allowEditing: false,
										sortOrder: 'none', // acs desc none
										allowHeaderFiltering: false,
										fixed: false, // 컬럼 fix 시 사용
										fixedPosition: 'left', // left or right
										requiredRule: {
											message: '직위명은 필수입니다.',
										},
									},
									{
										caption: '사용 여부',
										dataField: 'viewCd',
										//width: 70,
										height: 40,
										alignment: 'center', // left center right
										visible: true,
										allowEditing: true,
										sortOrder: 'none', // acs desc none
										allowHeaderFiltering: false,
										fixed: false, // 컬럼 fix 시 사용
										fixedPosition: 'left', // left or right
										lookup: {
											dataSource: this.$_enums.common.integerViewFlag.values,
											displayExpr: 'label',
											valueExpr: 'value',
										},
									},
								],
							},
							//직책
							position: {
								refName: 'positionGridRefName',
								allowColumnResizing: true, //컬럼 사이즈 허용
								showBorders: false, //border 유무
								showColumnHeaders: true, //컬럼 헤더 유무
								showColumnLines: false, //컬럼 세로선 유무
								showRowLines: true, //컬럼 가로선 유무
								rowAlternationEnabled: false,
								dataSource: [],
								dataSourceDefaultSortColumn: '+gradeOrd', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순
								// width:'200',                                     // 주석처리시 100%
								height: 'calc(100vh - 300px)', // 주석처리시 100%
								apiActionNm: {
									selectPosition: 'POSITION_LIST_ALL',
									update: 'POSITION_LIST_UPDATE',
									delete: 'POSITION_LIST_DELETE',
								},
								showActionButtons: {
									// 그리드 버튼 노출 설정값
									select: false,
									update: true,
									delete: true,
									customButtons: [],
								},
								customEvent: {
									selectionChanged: false,
									initNewRow: true,
									cellPrepared: false,
									rowPrepared: false,
									contentReady: false,
									optionChanged: false,
									exporting: false,
									rowInserting: false,
									rowInserted: false,
									rowUpdating: false,
									rowUpdated: false,
									rowRemoved: false,
									saving: true,
								},
								dragging: {
									sortColumn: 'gradeOrd',
									allowReordering: true,
									dropFeedbackMode: 'push', // 설정하면 dragging 할때 기존리스트가 아래로 움직이는 효과
								},
								grouping: {
									contextMenuEnabled: false,
									autoExpandAll: false,
									allowCollapsing: true,
									expandMode: 'rowClick', // rowClick or buttonClick
								},
								groupPanel: {
									visible: false,
								},
								columnChooser: {
									enabled: false, // 컬럼 Chooser 버튼 사용유무
								},
								loadPanel: {
									enabled: false, // 로딩바 표시 유무
								},
								sorting: {
									mode: 'multiple', // single multiple
								},
								scrolling: {
									// 미사용시 주석처리
									mode: 'virtual', //스크롤 모드 : ['infinite', 'standard', 'virtual']
								},
								remoteOperations: {
									// 서버사이드 여부
									filtering: false,
									sorting: false,
									grouping: false,
									paging: false,
								},
								paging: {
									// scrolling 미사용시만 적용됨
									enabled: true,
									pageSize: 10,
									pageIndex: 0, // 시작페이지
								},
								pager: {
									visible: false, //페이저 표시 여부
									showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
									allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
									displayMode: 'compact', //표시 모드 : ['full', 'compact']
									showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
									showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
								},
								filterRow: {
									visible: false,
								},
								headerFilter: {
									visible: true,
								},
								editing: {
									allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
									allowDeleting: false,
									allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
									mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
									startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
									selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
								},
								selecting: {
									mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
									selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
									showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
								},
								columns: [
									{
										caption: '순서',
										dataField: 'gradeOrd',
										//width: 70,
										//height: 40,
										alignment: 'center', // left center right
										visible: false,
										//allowEditing: false,
										sortOrder: 'none', // acs desc none
										allowHeaderFiltering: false,
										fixed: false, // 컬럼 fix 시 사용
										fixedPosition: 'left', // left or right
									},
									{
										caption: '사이트*',
										dataField: 'siteId',
										//width: 70,
										//height: 40,
										alignment: 'center', // left center right
										visible: true,
										//allowEditing: false,
										sortOrder: 'none', // acs desc none
										allowHeaderFiltering: false,
										fixed: false, // 컬럼 fix 시 사용
										fixedPosition: 'left', // left or right
										lookup: {
											dataSource: this.$store.getters.getSiteList,
											displayExpr: 'siteNm',
											valueExpr: 'id',
										},
										requiredRule: {
											message: '사이트는 필수입니다.',
										},
									},

									{
										caption: '직책명*',
										dataField: 'gradeNm',
										//width: 70,
										//height: 40,
										alignment: 'center', // left center right
										visible: true,
										//allowEditing: false,
										sortOrder: 'none', // acs desc none
										allowHeaderFiltering: false,
										fixed: false, // 컬럼 fix 시 사용
										fixedPosition: 'left', // left or right
										requiredRule: {
											message: '직위명은 필수입니다.',
										},
									},
									{
										caption: '사용 여부',
										dataField: 'viewCd',
										//width: 70,
										height: 40,
										alignment: 'center', // left center right
										visible: true,
										allowEditing: true,
										sortOrder: 'none', // acs desc none
										allowHeaderFiltering: false,
										fixed: false, // 컬럼 fix 시 사용
										fixedPosition: 'left', // left or right
										cellTemplate: (container, options) => {
											let DxSwitch = this.config.dxComponent.dxSwitch; //vue페이지에서 설정된 값
											let switchBtn = new DxSwitch({
												propsData: {
													value: options.value,
													onValueChanged: () => {
														vm.onChangedViewCd(options.data);
													},
												},
											});
											switchBtn.$mount();
											container.append(switchBtn.$el);
										},
									},
								],
							},
						},
					},
				},
			},
			dataGrid: {},
		};
	},
	computed: {
		/** @description: pageData -> pageSetting apiActionNm 가져오기 */
		getApiActionNm() {
			return this.config.pageSetting.config ? this.config.pageSetting.config.apiActionNm : null;
		},
	},
	methods: {
		/** @description: 현재 선택된 탭 index */
		tabSelectedIndex(index) {
			this.$log.debug('tabSelectedIndex', index);
			this.tabs.selectedIndex = index;

			/*** 검색 관련 처리 ***/
			//this.searchType.paramsData = null;  //검색 param 데이터 초기화
			//this.$set(this.searchType.obj, 'selectedTabIndex', index);  //탭 인덱스 검색 컴포넌트로 props
			/*** 검색 관련 처리 ***/

			//pageData pageSetting config 셋팅
			//await this.$_setPageSettingConfig();

			this.selectDataList();
		},
		/** @description: 탭별 데이터 조회, 공통 검색 조회시 필요 */
		selectDataList() {
			if (this.tabs.selectedIndex === 0) {
				//직위
				this.selectGradeGridList();
			} else if (this.tabs.selectedIndex === 1) {
				//직책
				this.selectPositionGridList();
			}
		},
		/** @description: 그리드 새로고침 메서드 */
		refreshDataList() {
			if (this.tabs.selectedIndex === 0) {
				this.$refs.gradeGrid.refreshData();
			} else {
				this.$refs.positionGrid.refreshData();
			}
		},
		/** @description: 직위 관리 조회 API 호출 메서드 */
		async selectGradeGridList(sort = '+gradeOrd') {
			this.dataGrid.grade.dataSource = new CustomStore({
				key: 'id',
				async load(loadOptions) {
					let params = vm.$_getDxDataGridParam(loadOptions);

					if (!params.sort) params.sort = sort;

					//params = { ...params, ...vm.searchType.paramsData };

					let payload;
					let res;

					payload = {
						actionname: 'GRADE_LIST_SELECT',
						data: { params: params },
						loading: false,
						useErrorPopup: false,
					};

					res = await vm.CALL_API(payload);
					if (isSuccess(res)) {
						let rtnData = {
							data: res.data.data,
							totalCount: res.data.header.totalCount,
						};

						vm.$refs.gradeGrid.totalCount = rtnData.totalCount;

						return rtnData;
					}
				},
				totalCount: opts => {
					//데이터 없는 경우 totalCount 에러 발생하여 추가
					return new Promise((resolve, reject) => {
						resolve(0);
					});
				},
			});
		},
		/** @description: 직책 관리 조회 API 호출 메서드 */
		async selectPositionGridList(sort = '+positionOrd') {
			this.dataGrid.position.dataSource = new CustomStore({
				key: 'id',
				async load(loadOptions) {
					let params = vm.$_getDxDataGridParam(loadOptions);

					if (!params.sort) params.sort = sort;

					//params = { ...params, ...vm.searchType.paramsData };

					let payload;
					let res;

					payload = {
						actionname: 'POSITION_LIST_ALL',
						data: { params: params },
						loading: false,
						useErrorPopup: false,
					};

					res = await vm.CALL_API(payload);
					if (isSuccess(res)) {
						let rtnData = {
							data: res.data.data,
							totalCount: res.data.header.totalCount,
						};

						vm.$refs.positionGrid.totalCount = rtnData.totalCount;

						return rtnData;
					}
				},
				totalCount: opts => {
					//데이터 없는 경우 totalCount 에러 발생하여 추가
					return new Promise((resolve, reject) => {
						resolve(0);
					});
				},
			});
		},
		/** @description: 로우 추가시 데이터 초기 설정 */
		onInitNewRow(e) {
			//e.data.gradeOrd = e.component.totalCount() + 1;
			let key;
			if (this.tabs.selectedIndex === 0) {
				key = 'grade';
			} else {
				key = 'position';
			}

			//순서
			let totalCount = e.component.totalCount();
			e.data[`${key}Ord`] = totalCount + 1;

			//사용여부
			e.data.viewCd = this.$_getUsedCode.id;
		},
		/** @description: 데이터 저장 */
		async onSaving(e) {
			// false 셋팅하면 grid에 binding된 data가 변경되어버림
			e.cancel = true;

			if (e.changes.length) {
				let data = [];
				if (e.changes && e.changes.length > 0) {
					e.changes.forEach(d => {
						this.$log.debug('e.changes data', d.data);
						if (d.type === 'update') {
							data.push({ id: d.key, ...d.data });
						} else if (d.type === 'insert') {
							if (Object.keys(d.data).length !== 0) {
								data.push({ id: null, ...d.data });
							}
						}
					});
				}

				let actionNm;
				if (this.tabs.selectedIndex === 0) {
					//actionNm = 'GRADE_LIST_UPDATE';
					actionNm = this.dataGrid.grade.apiActionNm.update;
				} else {
					//actionNm = 'POSITION_LIST_UPDATE';
					actionNm = this.dataGrid.position.apiActionNm.update;
				}

				let payload = {
					actionname: actionNm,
					data: { data: data },
					loading: true,
				};

				let res = await this.CALL_API(payload);
				if (isSuccess(res)) {
					// await e.component.refresh(true);
					this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
					e.component.cancelEditData();
					this.refreshDataList();
				} else {
					e.component.cancelEditData();
				}
			}
		},
		/** @description: 데이터 삭제 이벤트(현재 사용 안함) */
		async onDeleteData() {
			let deletedIds; //삭제할 데이터

			let payload;
			let res;

			let contents;
			if (this.tabs.selectedIndex === 0) {
				deletedIds = this.$refs.gradeGrid.selectedRowsData.map(d => {
					return { id: d.id };
				}); //삭제할 데이터
				contents = '직위 정보를 삭제하시겠습니까?';
			} else {
				deletedIds = this.$refs.positionGrid.selectedRowsData.map(d => {
					return { id: d.id };
				}); //삭제할 데이터
				contents = '직책 정보를 삭제하시겠습니까?';
			}

			if (deletedIds.length === 0) {
				return this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
			}

			if (await this.$_Confirm(contents)) {
				if (this.tabs.selectedIndex === 0) {
					payload = {
						actionname: 'GRADE_LIST_DELETE',
						data: { data: { data: deletedIds } },
						loading: true,
					};
				} else {
					payload = {
						actionname: 'POSITION_LIST_DELETE',
						data: { data: { data: deletedIds } },
						loading: true,
					};
				}

				res = await this.CALL_API(payload);

				if (isSuccess(res)) {
					this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));
					this.refreshDataList();
				}
				/*if(res.status === 200){
                    if(res.data.header.resCode === "success") {
                        this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));
                        this.refreshDataList();

                    }else {
                        return this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
                    }
                }else {
                    if (res.data.header.resCode === "C016") {
                        return this.$_Msg(res.data.header.resMsg);
                    }
                }*/
			} else {
				return false;
			}
		},
		/** @description: View 노출 상태 변경 이벤트 */
		async onChangedViewCd(item) {
			//this.$log.debug(item);
			//let rowData = item.row.data;
			/* let data = [ item ];

            let payload = {
                actionname: 'USER_LIST_UPDATE',
                data: { data : data },
                loading: true,
            }
            let res = await this.CALL_API(payload);

            if(res.status === 200){
                if(res.data.header.resCode === "success") {
                    this.$_Toast('변경되었습니다.');
                    this.refreshGridList();
                }else {
                    this.$_Toast('업데이트 실패 :(');
                }
            } */
		},
		/** @description: 그리드 상단 커스텀 버튼 설정(pageData에서 onClick 이벤트의 vm을 찾지 못하여 여기서 설정)  */
		setGridCustomButtons() {
			//직위 커스텀 버튼
			let gradeCustomButtons = [
				{
					widget: 'dxButton',
					options: {
						icon: '',
						text: '삭제',
						elementAttr: { class: 'btn_XS white light_filled trash' },
						width: 60,
						height: 30,
						onClick() {
							vm.onDeleteData();
						},
					},
					location: 'before',
				},
			];
			this.dataGrid.grade.showActionButtons.customButtons = gradeCustomButtons;

			//직책 커스텀 버튼
			let positionCustomButtons = [
				{
					widget: 'dxButton',
					options: {
						icon: '',
						text: '삭제',
						elementAttr: { class: 'btn_XS white light_filled trash' },
						width: 60,
						height: 30,
						onClick() {
							vm.onDeleteData();
						},
					},
					location: 'before',
				},
			];
			this.dataGrid.position.showActionButtons.customButtons = positionCustomButtons;
		},
		/** @description: pageData로 설정된 그리드 데이터 설정 */
		setPageDataGrid() {
			//pageData pageSetting config 셋팅
			//this.$_setPageSettingConfig();

			//pageData의 리스트 항목 설정
			this.$_setPageSettingContentsData();
			this.dataGrid = this.config.pageSetting.contentsData.dataGrid;

			//그리드 상단 커스텀 버튼 설정
			//this.setGridCustomButtons();
		},
		/** @description : 라이프사이클 created 호출되는 메서드 */
		createdData() {
			vm = this;

			//pageData로 설정된 그리드 설정
			this.setPageDataGrid();
		},
		/** @description : 라이프사이클 mounted 호출되는 메서드 */
		mountedData() {
			this.selectDataList();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		//this.mountedData();
	},
	destroyed() {},
};
</script>

<style scoped></style>
